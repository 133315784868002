import router from "@/router";
import store from "@/store";
import axios from "axios";
import ElementUI from 'element-ui';
var config = {
    //配置请求地址
    baseURL: "https://ll.xuejimeixiang.cn/api",
    //配置超时信息
    timeout: 10000,
}
//创建axios实例
var instance = axios.create(config);
var flag = 0;

//配置拦截器信息
instance.interceptors.request.use((config) => {
    console.log(config.url);
    if (config.url === '/user/login') {
        flag = 1;
    }
    else {
        console.log(store.state.LoginForm.token);
        config.headers.login_token = store.state.LoginForm.token
    }
    return config
}, (error) => {
    return Promise.reject(error);
})

instance.interceptors.response.use(
    (response) => {
        console.log("返回响应码: ", response.status, "-----");
        if (flag === 1) {
            let rtoken = response.data.data.token;
            let rrole = response.data.data.role;
            let rnickname = response.data.data.nickname;
            let rurl = response.data.data.img;
            store.commit('login', { token: rtoken, role: rrole,nickname: rnickname, img: rurl })
            console.log("登录后信息: ",store.state.LoginForm)
            flag = 0;
        }
        return response
    },
    (error) => {
        console.log(error)
        if (error.response.status === 401) {
            ElementUI.Message({
                title: '登录过期',
                message: '登录过期,请重新登录',
                type: 'error'
            });
            router.push('/')
        }
        return error
    }
)

export default instance