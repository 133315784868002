import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
 
// 用来存储数据
const state = {
    LoginForm: {
        token: null,
        role: null,
        nickname: null,
        img: null,
    },
}
// 响应组件中的事件
const actions = {
 
}
// 操作数据
const mutations = {
 login(state,form){
    state.LoginForm=form
 }
}

// 用来将state数据进行加工
const getters = {
    getLoginForm(state){
        return state.LoginForm
    }
}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})
 