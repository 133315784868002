import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/CC',
    name: 'CC',
    component: () => import('@/views/IndexView.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        component:() => import('@/views/NoticeView.vue'),
      },
      {
        path: 'personalCenter',
        name: 'personalCenter',
        component:() => import('@/views/PersonalCenterView.vue'),
      },
      {
        path: 'noticeManage',
        name: 'noticeManage',
        component:() => import('@/views/NoticeManageView.vue'),
      },
      {
        path: 'origin',
        name: 'origin',
        component:() => import('@/views/OriginView.vue'),
      },{
        path: 'products',
        name: 'products',
        component:() => import('@/views/ProductsView.vue'),
      },
      {
        path: 'order',
        name: 'order',
        component:() => import('@/views/OrderView.vue'),
      },{
        path: 'orderForAdmin',
        name: 'orderForAdmin',
        component:() => import('@/views/OrderViewForAdmin.vue'),
      },
      {
        path: 'purchase',
        name: 'purchase',
        component:() => import('@/views/PurchaseView.vue'),
      },
      {
        path: 'warehouse',
        name: 'warehouse',
        component:() => import('@/views/WarehouseView.vue'),
      },
      {
        path: 'user',
        name: 'user',
        component:() => import('@/views/UserView.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
