<template>
  <div>
    <router-view></router-view>
    <!-- <Index-view></Index-view> -->
  </div>
</template>

<style></style>
<script>
// import Index from './views/Index.vue'

export default {
  components: {},
};
const debounce = (fn, delay) => {
  let timer
   return (...args) => {
     if (timer) {
       clearTimeout(timer)
     }
     timer = setTimeout(() => {
       fn(...args)
     }, delay)
   }
}
  
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
   constructor(callback) {
     callback = debounce(callback, 200);
     super(callback);
   }
}
</script>
<style></style>
